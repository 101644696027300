/*! `xml` grammar compiled for Highlight.js 11.9.0 */
var hljsGrammar=(()=>{ 'use strict';return e=>{
    const a=e.regex,n=a.concat(/[\p{L}_]/u,a.optional(/[\p{L}0-9_.-]*:/u),/[\p{L}0-9_.-]*/u),s={
            className:'symbol',begin:/&[a-z]+;|&#[0-9]+;|&#x[a-f0-9]+;/},t={begin:/\s/,
            contains:[{className:'keyword',begin:/#?[a-z_][a-z1-9_-]+/,illegal:/\n/}]
        },i=e.inherit(t,{begin:/\(/,end:/\)/}),c=e.inherit(e.APOS_STRING_MODE,{
            className:'string'}),l=e.inherit(e.QUOTE_STRING_MODE,{className:'string'}),r={
            endsWithParent:!0,illegal:/</,relevance:0,contains:[{className:'attr',
                begin:/[\p{L}0-9._:-]+/u,relevance:0},{begin:/=\s*/,relevance:0,contains:[{
                className:'string',endsParent:!0,variants:[{begin:/"/,end:/"/,contains:[s]},{
                    begin:/'/,end:/'/,contains:[s]},{begin:/[^\s"'=<>`]+/}]}]}]};return{
        name:'HTML, XML',
        aliases:['html','xhtml','rss','atom','xjb','xsd','xsl','plist','wsf','svg'],
        case_insensitive:!0,unicodeRegex:!0,contains:[{className:'meta',begin:/<![a-z]/,
            end:/>/,relevance:10,contains:[t,l,c,i,{begin:/\[/,end:/\]/,contains:[{
                className:'meta',begin:/<![a-z]/,end:/>/,contains:[t,i,l,c]}]}]
        },e.COMMENT(/<!--/,/-->/,{relevance:10}),{begin:/<!\[CDATA\[/,end:/\]\]>/,
            relevance:10},s,{className:'meta',end:/\?>/,variants:[{begin:/<\?xml/,
            relevance:10,contains:[l]},{begin:/<\?[a-z][a-z0-9]+/}]},{className:'tag',
            begin:/<style(?=\s|>)/,end:/>/,keywords:{name:'style'},contains:[r],starts:{
                end:/<\/style>/,returnEnd:!0,subLanguage:['css','xml']}},{className:'tag',
            begin:/<script(?=\s|>)/,end:/>/,keywords:{name:'script'},contains:[r],starts:{
                end:/<\/script>/,returnEnd:!0,subLanguage:['javascript','handlebars','xml']}},{
            className:'tag',begin:/<>|<\/>/},{className:'tag',
            begin:a.concat(/</,a.lookahead(a.concat(n,a.either(/\/>/,/>/,/\s/)))),
            end:/\/?>/,contains:[{className:'name',begin:n,relevance:0,starts:r}]},{
            className:'tag',begin:a.concat(/<\//,a.lookahead(a.concat(n,/>/))),contains:[{
                className:'name',begin:n,relevance:0},{begin:/>/,relevance:0,endsParent:!0}]}]} }
})();export default hljsGrammar;