<template>
    <div style="padding: 15px;">
        <ViewForm :rule="preview.rule" :option="preview.option" v-model:api="preview.api" v-if="preview.state">
        </ViewForm>
    </div>
</template>

<script>


//#region 数据新增所走的页面引入 start
import viewForm from '../src/utils/form';
//#endregion 数据新增所走的页面引入 end
export default {
    name: 'previewComponents',
    props: {
        preview: Object
    },
    components: {
        ViewForm: viewForm.$form(),
    }
}
</script>

<style lang="scss" scoped></style>